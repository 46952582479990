import '../styles/csa.scss';

import livechatScreen from '../assets/AIM Livechat 3.png';

const Csa = () => {
  return (
    <div>
      <img src={livechatScreen} id='livechat-screen'/>
    </div>

  );
};

export default Csa;
import { useLayoutEffect, useState, useEffect, useCallback } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import '#components/portal/portal.scss';

import aimScreen from '../assets/AIM Home Page.png';

// Components
import { PortalBanner, PortalBody, PortalFooter } from '#components/portal';
import { FloatingChatbot } from '#components/floatingChatbot';
// Hooks
import { useTheme, useChatbot } from '#hooks';

import { socket } from '../socket';

const ConnectionState = {
  PENDING: 'PENDING',
  WAITING: 'WAITING',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};

const Portal = ({ connectedWs, connectionFail, isTimeout }) => {
  useTheme();
  const { agentCode } = useParams();
  const { chatbot, subscribeNotification, initializeChatbot } = useChatbot();
  const { isLoaded } = chatbot;
  const [connectionState, setConnectionState] = useState(
    ConnectionState['PENDING']
  );

  const requestConnection = () => {
    console.log('requestConnection');
    socket.emit('join', (data) => {
      console.log('join', data);
      if (data === true) {
        setConnectionState(ConnectionState['SUCCESS']);
      } else {
        setConnectionState(ConnectionState['FAIL']);
      }
    });
  };

  const releaseConnection = () => {
    console.log('leave');
    socket.emit('leave');
  };

  useEffect(() => {
    if (!connectedWs) {
      return;
    }
    requestConnection();
    return () => {
      releaseConnection();
    };
  }, [connectedWs]);

  useLayoutEffect(() => {
    if (chatbot.isLoaded) return;
    initializeChatbot();
  }, [initializeChatbot, chatbot.isLoaded]);

  // useLayoutEffect(() => {
  //   const abortController = new AbortController();
  //   if (agentCode)
  //     subscribeNotification({ agentCode, signal: abortController.signal });

  //   return () => {
  //     abortController.abort();
  //   };
  // }, [subscribeNotification, agentCode]);

  console.log(connectionState);

  if (isTimeout) {
    return <Navigate to="/demo/timeout" />;
  }

  if (connectionFail) {
    return <Navigate to="/demo/connection-fail" />;
  }

  if (connectionState === ConnectionState['PENDING']) {
    // Loading screen
    return (
      <div className="loading-container">
        <div className="loading-content">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (connectionState === ConnectionState['FAIL']) {
    return <Navigate to="/demo/waiting-queue" />;
  }

  return (
    <div className="portal">
      <img src={aimScreen} />
      {isLoaded && <FloatingChatbot />}
      {/* <PortalBanner />
      <PortalBody />
      <PortalFooter /> */}
    </div>
  );
};

export default Portal;
